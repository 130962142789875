<template>
  <div
    id="content"
    :style="{ height: seatRow > 5 ? '100%' : 'calc(100vh - 74px)' }"
  >
    <div id="herder">
      <el-row id="herder_body">
        <el-col :span="1">
          <el-button
            v-throttle
            @click="
              () => {
                this.$router.go(-1);
              }
            "
            size="mini"
            icon="el-icon-back"
            class="returnBtn"
            round
            >返回</el-button
          >
        </el-col>
        <el-col :span="20" id="header_title">{{ drawInfo.examName }}</el-col>
        <el-col :span="3" id="header_timeing">{{ timeing }}</el-col>
      </el-row>
    </div>
    <div id="main_box">
      <div id="background_box"></div>
      <div id="exam_box">
        <el-row>
          <el-col :span="8" class="main_body_batch">
            <span style="font-weight: bold; margin-right: 10px;">选择考场:</span>
            <el-select
              v-model="queryParam.examPlace"
              placeholder="请选择考场"
              @change="chooseExamPlace"
            >
              <el-option
                v-for="item in examPlaceList"
                :key="item.examPlaceId"
                :label="item.sysOrgExamPlaceRoomName"
                :value="item.examPlaceId"
              ></el-option>
            </el-select>
          </el-col>
          <el-col :span="8" class="main_body_batch">
            <span style="font-weight: bold">考试批次:</span>
            <el-button
              v-throttle
              icon="el-icon-arrow-left"
              type="primary"
              size="middle"
              @click="handlePageChange(-1)"
              :disabled="leftBtnDisabled"
              style="margin:0 10px;"
            ></el-button>
            <el-select
              v-model="queryParam.examSession"
              placeholder="请选择批次"
              @change="chooseExamSession"
              filterable 
            >
              <el-option
                v-for="(item, i) in examSessionList"
                :key="item"
                :value="item + '/' + examSessionLen"
                :label="labelValue(item, i)"
              ></el-option>
            </el-select>
            
            <el-button
              v-throttle
              icon="el-icon-arrow-right"
              type="primary"
              size="middle"
              @click="handlePageChange(1)"
              :disabled="rightBtnDisabled"
              style="margin:0 10px;"
            ></el-button>
          </el-col>
          <el-col :span="8" id="main_body_distance">
            <span style="font-weight: bold">距离抽签：</span>
            <span style="color: red">{{ showTime }}</span>
          </el-col>
        </el-row>
      </div>
      <div
        id="main"
        :style="{ width: 284 * seatColoum + 16 * seatColoum + 90 + 'px' }"
      >
        <div id="seat_box">
          <el-row :gutter="23">
            <el-col :span="22" id="seat_body">
              <el-card shadow="always">
                <el-row
                  :gutter="10"
                  v-for="(item, index) in defaultSeatList"
                  :key="index"
                >
                  <div v-for="seat in item" :key="seat">
                    <el-col style="width: 284px" class="seat_badge">
                      <div
                        id="seat_container"
                        :style="seat == 999 ? 'visibility: hidden;' : ''"
                      >
                        <div style="position: absolute;right: 0;top: -4px;">
                          <img style="width: 16px; height: 20px" src="./img/seat.png" alt />
                        </div>
                        <div id="seat_info">
                          <el-row style="display: flex;">
                            <div id="seat_num" style="width: 50px">{{ seat }}</div>
                            <div id="examinee_info">
                              <div
                                v-for="item in drawingPlatformExamineeList"
                                :key="item.studentId"
                              >
                                <div
                                  v-if="item.seatCode == seat"
                                  class="omit_box"
                                  style="font-size: 21px;height: 55px;width: 180px;"
                                >
                                  {{ item.studentName }}
                                </div>
                                <div
                                  v-if="item.seatCode == seat"
                                  class="omit_box"
                                >
                                  {{ item.examineeCode }}
                                </div>
                              </div>
                            </div>
                          </el-row>
                        </div>
                      </div>
                    </el-col>
                  </div>
                </el-row>
              </el-card>
            </el-col>

            <el-col :span="1" class="btnBox">
              <div>
                <el-button
                  v-throttle
                  type="primary"
                  @click="drawing"
                  icon="el-icon-receiving"
                  :disabled="
                    !queryParam.examPlace || !queryParam.examSession || isDraw
                  "
                  style="width: 120px;"
                  >抽签</el-button
                >
              </div>
              <!-- <div style="display: flex;margin: 10px 0">
                <el-button
                  v-throttle
                  icon="el-icon-arrow-left"
                  type="primary"
                  @click="handlePageChange(-1)"
                  :disabled="leftBtnDisabled"
                ></el-button>
                <el-button
                  v-throttle
                  icon="el-icon-arrow-right"
                  type="primary"
                  @click="handlePageChange(1)"
                  :disabled="rightBtnDisabled"
                ></el-button>
              </div> -->
              <div style="margin: 10px 0">
                <el-button
                  v-throttle
                  type="primary"
                  @click="A4Print"
                  icon="el-icon-printer"
                  :disabled="drawingPlatformExamineeList.length <= 0"
                  style="width: 120px;"
                  >A4打印</el-button
                >
              </div>
              <div>
                <el-button
                  v-throttle
                  type="primary"
                  @click="labelPrint"
                  icon="el-icon-printer"
                  :disabled="drawingPlatformExamineeList.length <= 0"
                  style="width: 120px;"
                  >标签打印</el-button
                >
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>

    <!-- A4打印区域 -->
    <vue-easy-print ref="a4PrintContentRef">
      <div id="A4_print_content" style="padding: 3px">
        <div id="A4_print_herder">
          <div id="A4_print_exam" style="margin-left: 5px">
            {{ drawInfo.examName }}
          </div>
          <div id="A4_print_place" style="margin-left: 5px">
            当前考场：{{ examPlace.sysOrgExamPlaceRoomName }}
          </div>
          <!-- <div id="A4_print_time">考试时间：2022</div> -->
          <div id="A4_print_session" style="margin-left: 5px">
            场次：{{ queryParam.examSession }}
          </div>
          <div id="seat_box">
            <el-row>
              <el-col
                :span="22"
                id="seat_body"
                :style="{ width: 280 * seatColoum + 36 + 'px' }"
              >
                <el-card shadow="always">
                  <el-row
                    :gutter="10"
                    v-for="(item, index) in defaultSeatList"
                    :key="index"
                  >
                    <div v-for="seat in item" :key="seat">
                      <el-col style="width: 280px" class="seat_badge">
                        <div
                          id="seat_container"
                          :style="seat == 999 ? 'visibility: hidden;' : ''"
                        >
                          <div style="position: absolute;right: 0;top: -4px;">
                            <img style="width: 16px; height: 20px" src="./img/seat.png" alt />
                          </div>
                          <div id="seat_info">
                            <el-row style="display: flex">
                              <div id="seat_num" style="margin-top: 8px; width: 50px;">{{ seat }}</div>
                              <div
                                id="examinee_info"
                                style="margin-left: 20px"
                              >
                                <div
                                  v-for="item in drawingPlatformExamineeList"
                                  :key="item.studentId"
                                >
                                  <div
                                    v-if="item.seatCode == seat"
                                    class="omit_box"
                                    style="font-size: 24px;height: 60px;"
                                  >
                                    {{ item.studentName }}
                                  </div>
                                  <div
                                    v-if="item.seatCode == seat"
                                    class="omit_box"
                                  >
                                    {{ item.examineeCode }}
                                  </div>
                                </div>
                              </div>
                            </el-row>
                          </div>
                        </div>
                      </el-col>
                    </div>
                  </el-row>
                </el-card>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
    </vue-easy-print>
  </div>
</template>

<object
  id="LODOP_OB"
  classid="clsid:2105C259-1E0C-4534-8141-A753534CB4CA"
  width="0"
  height="0"
>
  <embed id="LODOP_EM" type="application/x-print-lodop" width=0 height=0></embed>
</object>
<script>
import vueEasyPrint from "vue-easy-print";
import { isEmpty, debounce } from "@/utils/util";
import {
  enterDrawApi,
  getDrawExamInfoApi,
  drawingApi,
} from "@/api/draw/draw.js";
import { systemTime } from "@/api/system/information.js";
import { countdown } from "@/utils/publicFunc.js";
import { getLodop } from "@/api/draw/LodopFuncs.js";
import { quit } from "@/api/auth.js";
import { removeToken, removeName, getLoginRoad } from "@/utils/token.js";
import { removeStore } from "@/utils/token.js";
import { inquireRule2 } from "@/api/exam/exam.js"
export default {
  components: {
    vueEasyPrint,
  },
  data() {
    return {
      urlParam: {
        sysOrgExamPlaceId: 0,
        examId: 0,
        examStartTime: 0,
        arrangeModel: 0, //考试模式(1定时定点 2随到随考)
        randomTimingType: 0, //随到随考录入模式（1分开点名 2集中点名）
      },
      //现在时间
      timeing: "",
      drawInfo: {},
      queryParam: {
        examPlace: "",
        examSession: "",
      },
      //考场
      examPlaceList: [],
      //单个考场
      examPlace: {},
      //批次(选择考场后)
      examSessionList: [],
      examSessionLen: 0,
      //控制抽签是否禁用
      isDraw: false,
      //定时器相关
      timeInterval: null,
      timeIntervalCount: 0,
      timer: null,

      timerCount: 0,
      //服务器时间戳
      serverNowTime: 0,
      serverNowTimer: 0,
      distanceExamStartTime: 0,
      //距离抽签时间
      showTime: "00:00:00",
      //默认座位数据(直接替换即可)
      defaultSeatList: [],
      //默认座位布局
      defaultSeatLayout: [
        [1, 7, 13, 19],
        [2, 8, 14, 20],
        [3, 9, 15, 21],
        [4, 10, 16, 22],
        [5, 11, 17, 23],
        [6, 12, 18, 24],
      ],
      //座位分布
      seatArrange: [],
      seatCodeArrange: [],
      drawingPlatformExamineeList: [],
      // autoDrawingCount: 0,
      customSeatWidth: 0,
      seatColoum: 0,
      seatRow: 0,
      examSessionInfoList:[], // 批次数组，用于跳转最新场次
      drawStatusList:[],
      drawModel: 1, // 抽签模式，1分开模式 2集中模式
      intervalTime: 50, // 抽签动画间隔时长
      durationTime: 3000, // 抽签动画总时长
    };
  },
  computed: {
    rightBtnDisabled(){
      return Number(
        this.queryParam.examSession.replace("/", ":").split(":")[0]
      ) === this.examSessionList.length
    },
    leftBtnDisabled(){
      return Number(
        this.queryParam.examSession.replace("/", ":").split(":")[0]
      ) === 1
    }
  },
  watch: {},
  created() {},
  mounted() {
    this.renderUrlParam();
  },
  methods: {
    // 获取抽签模式信息
    fetchDrawMode(id) {
      inquireRule2(id).then(res => {
        if(res.success) {
          this.drawModel = res.data.drawModel
        }
      }).catch(err => {
        console.log(err)
      })
    },
    renderUrlParam() {
      this.urlParam.sysOrgExamPlaceId = 0;
      this.urlParam.sysOrgExamPlaceId = Number(
        this.$route.query.sysOrgExamPlaceId
      );
      this.urlParam.examId = 0;
      this.urlParam.examId = Number(this.$route.query.examId);
      this.examStartTime = 0;
      this.urlParam.examStartTime = Number(this.$route.query.examStartTime);
      this.arrangeModel = 0;
      this.urlParam.arrangeModel = Number(this.$route.query.arrangeModel);
      this.examStartTime = 0;
      this.urlParam.randomTimingType = Number(
        this.$route.query.randomTimingType
      );
      this.fetchDrawMode(this.urlParam.examId)
      this.initInterval();
      this.enterDraw();
      this.initSeatPanel();
    },
    //获取考试信息及场次集合信息
    enterDraw() {
      enterDrawApi(this.urlParam.examId, this.urlParam.sysOrgExamPlaceId).then(
        (res) => {
          if (res.success) {
            this.drawInfo = {};
            this.drawInfo = res.data;
            //渲染考场信息
            this.examPlaceList = [];
            this.examPlaceList =
              this.drawInfo.drawingPlatformPlaceRoomDrawingList;
            //默认选中第一个考场
            this.queryParam.examPlace = this.examPlaceList[0].examPlaceId;
            this.chooseExamPlace();
          } else {
            if (res.code != "500") {
              this.msgWarning(res.msg);
            }
            this.$router.push({ path: "/draw/report" });
          }
        }
      );
    },
    //选择考场
    async chooseExamPlace() {
      //考场信息置空
      this.examSessionList = [];
      this.queryParam.examSession = "";
      this.drawStatusList = []
      //考生及座位信息置空
      this.drawingPlatformExamineeList = [];
      // 获取最新的抽签数据
      let { data } = await enterDrawApi(this.urlParam.examId, this.urlParam.sysOrgExamPlaceId)
      this.examPlaceList = data.drawingPlatformPlaceRoomDrawingList
      this.examPlaceList.forEach((item) => {
        if (this.queryParam.examPlace == item.examPlaceId) {
          this.examPlace = {};
          this.examPlace = item;
          this.seatArrange = [];
          this.seatCodeArrange = [];
          this.seatCodeArrange = this.examPlace.seatCodeArrange;
          this.seatArrange = this.examPlace.seatArrange;
          this.seatRow = 0;
          this.seatRow = this.seatArrange.length;
          this.seatColoum = 0;
          // this.seatColoum = this.seatArrange[0].length
          this.seatColoum = Math.max(
            ...this.seatArrange.map((item) => item.length)
          );
          this.customSeatWidth = 0;
          this.customSeatWidth = 24 / this.seatArrange[0].length;
          if (this.seatArrange[0].length > 4) {
            this.customSeatWidth = 6;
          }
          //替换座位布局
          this.defaultSeatList = [];
          // this.seatArrange.forEach((arr) => {
          this.seatCodeArrange.forEach((arr) => {
            this.defaultSeatList.push(arr);
          });
          //渲染批次信息
          this.examSessionList = item.examSessionList;
          this.examSessionInfoList = item.examSessionInfoList;
          if (this.urlParam.arrangeModel == 2) {
            if (isEmpty(this.examSessionList)) {
              this.msgWarning("未完成点名");
              return;
            }
          }
          if (!isEmpty(this.examSessionList)) {
            this.examSessionLen = 0;
            this.examSessionLen = this.examSessionList.length;
            // // 默认选中第一批
            // this.queryParam.examSession = `${this.examSessionList[0]}/${this.examSessionLen}`;
            // 默认已抽签的最后一批
            let firstUnDrawIndex
            for (let i = 0; i < this.examSessionInfoList.length; i++) {
              if(!this.examSessionInfoList[i].drawing) {
                // 找到第一个未抽签批次并跳出循环
                firstUnDrawIndex = i
                break
              }
            }
            this.examSessionInfoList.forEach((it,i)=>{
              this.drawStatusList[i] = it.drawing
            })
            if(typeof firstUnDrawIndex === 'undefined') {
              firstUnDrawIndex = this.examSessionInfoList.length
            }
            if(firstUnDrawIndex === 0) {
              this.queryParam.examSession = `${this.examSessionList[0]}/${this.examSessionLen}`;
            } else {
              this.queryParam.examSession = `${firstUnDrawIndex}/${this.examSessionLen}`;
            }
            this.chooseExamSession();
          }
        }
      });
    },
    //选择批次
    chooseExamSession() {
      clearInterval(this.timer);
      let examSession = Number(
        this.queryParam.examSession.replace("/", ":").split(":")[0]
      );
      this.getDrawExamInfo(examSession);
    },
    getDrawExamInfo(examSession, isShowAnimation = false) {
      this.drawingPlatformExamineeList = [];
      getDrawExamInfoApi(this.queryParam.examPlace, examSession).then((res) => {
        //获取距离抽签剩余时间
        systemTime().then((sys) => {
          this.isDraw = true;
          if (sys.success) {
            //服务器现在时间
            this.serverNowTime = sys.data;
            //距离抽签时间
            this.distanceExamStartTime =
              sys.data - res.data.ableDrawingTimeSegment;
            this.timer = setInterval(() => {
              this.showTime = "00:00:00";
              ++this.timerCount;
              //每隔30s同步一次
              if (this.timerCount == 30) {
                getDrawExamInfoApi(this.queryParam.examPlace, examSession).then(
                  (from) => {
                    systemTime().then((val) => {
                      this.serverNowTime = val.data;
                      this.distanceExamStartTime =
                        val.data - from.data.ableDrawingTimeSegment;
                    });
                  }
                );
                this.timerCount = 0;
              }
              if (this.distanceExamStartTime < this.serverNowTime) {
                this.showTime = countdown(
                  this.distanceExamStartTime,
                  this.serverNowTime
                );
                this.serverNowTime -= 1000;
              } else {
                clearInterval(this.timer);
                this.isDraw = false;
                /*  this.autoDrawingCount += 1;
                  if (this.autoDrawingCount == 1) {
                  if (from.data.ableDrawingTimeSegment == 0) {
                  //自动抽签
                  this.drawing();
                  }
                  this.autoDrawingCount = 0;
                  } */
              }
            }, 1000);
          }
        });
        if (res.success) {
          // 点击抽签按钮情况
          if (res.data.drawing && isShowAnimation) {
            // this.seatArr11111ange
            let tempData = []
            this.$message({
              type: 'info',
              message: '开始抽签',
              duration: this.durationTime,
            });
            this.seatArrange.forEach((arr, i) => {
              arr.forEach((item, j) => {
                if (!isEmpty(item) && !isNaN(item)) {
                  res.data.drawingPlatformExamineeList.forEach(
                    (drawingPlatformExaminee) => {
                      if (drawingPlatformExaminee.examineeSeatNum == item) {
                        tempData.push({
                          ...drawingPlatformExaminee,
                          seatCode: this.seatCodeArrange[i][j],
                        });
                      }
                    }
                  );
                }
              });
            });
            this.drawingPlatformExamineeList = JSON.parse(JSON.stringify(tempData))
            let timerId = this.startShuffling(this.drawingPlatformExamineeList, this.intervalTime);
            setTimeout(() => {
              this.$message({
                type: 'success',
                message: '抽签完成',
              });
              clearInterval(timerId)
              this.drawingPlatformExamineeList = tempData
            }, this.durationTime)
          } else if (res.data.drawing && !isShowAnimation) {
            // 获取抽签数据，不点击抽签按钮情况
            this.seatArrange.forEach((arr, i) => {
              arr.forEach((item, j) => {
                if (!isEmpty(item) && !isNaN(item)) {
                  res.data.drawingPlatformExamineeList.forEach(
                    (drawingPlatformExaminee) => {
                      if (drawingPlatformExaminee.examineeSeatNum == item) {
                        this.drawingPlatformExamineeList.push({
                          ...drawingPlatformExaminee,
                          seatCode: this.seatCodeArrange[i][j],
                        });
                      }
                    }
                  );
                }
              });
            });
          }
        }
      });
    },
    // 数组洗牌
    shuffleArray(arr) {
      let array = [...arr]
      console.log(array)
      for (let i = array.length - 1; i >= 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        let temp1, temp2
        temp1 = array[i].studentName
        array[i].studentName = array[j].studentName
        array[j].studentName = temp1
        temp2 = array[i].examineeCode
        array[i].examineeCode = array[j].examineeCode
        array[j].examineeCode = temp2

      }
      return array;
    },
    // 开始洗牌
    startShuffling(array, interval) {
      return setInterval(() => {
          this.drawingPlatformExamineeList = this.shuffleArray(array);
      }, interval);
    },
    initInterval() {
      systemTime().then((res) => {
        this.serverNowTimer = res.data;
      });
      this.timeInterval = setInterval(() => {
        ++this.timeIntervalCount;
        //每隔30s同步一次
        if (this.timeIntervalCount == 30) {
          systemTime().then((res) => {
            this.serverNowTimer = res.data;
          });
          this.timeIntervalCount = 0;
        }
        let currTime = this.getCurrentTime();
        this.timeing = currTime;
        this.serverNowTimer += 1000;
      }, 1000);
    },
    //获取现在时间
    getCurrentTime() {
      let date = new Date(this.serverNowTimer);
      // 获取时间：时分秒
      const hour =
        date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
      const minute =
        date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
      const secound =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();

      // 获取日期：年月日
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();

      // 获取星期几
      const weeks = new Array(
        "星期日",
        "星期一",
        "星期二",
        "星期三",
        "星期四",
        "星期五",
        "星期六"
      );
      let nowWeek = weeks[new Date().getDay()];
      return `${year}/${month}/${day} ${nowWeek} ${hour}:${minute}:${secound}`;
    },
    initSeatPanel() {
      this.defaultSeatList = [];
      this.defaultSeatLayout.forEach((arr) => {
        arr.forEach((item) => {
          if (!isEmpty(item) && !isNaN(item)) {
            this.defaultSeatList.push(item);
          }
        });
      });
    },
    drawing: debounce(function () {
      //已经抽签
      if (this.drawingPlatformExamineeList.length > 0) {
        this.msgWarning("当前场次已抽签");
        return;
      }
      this.isDraw = true;
      let examSession = Number(
        this.queryParam.examSession.replace("/", ":").split(":")[0]
      );
      drawingApi(this.queryParam.examPlace, examSession).then((res) => {
        this.isDraw = false;
        if (res.success) {
          clearInterval(this.timer);
          this.getDrawExamInfo(examSession, true);
          this.drawStatusList[examSession - 1] = true
        } else {
          if (res.code != "500") {
            this.msgWarning(res.msg);
          }
          this.$router.push({ path: "/draw/report" });
        }
      });
    }, 200),
    A4Print() {
      this.$refs.a4PrintContentRef.print();
    },
    labelPrint() {
      let examPlaceName = this.getCurrentExamPlaceName()
      // 不同抽签模式的布局
      let ariaArr = this.drawModel === 1 ? [5,20,35,50] : [15, 45, 75]
      if(examPlaceName.length > 8 && this.drawModel === 1) {
        // 考场名称长度大于8的布局
        ariaArr = [5,37,52,67]
      }
      let LODOP = getLodop();
      LODOP.PRINT_INIT("");
      let _labelPrintList = this.drawingPlatformExamineeList.sort(function (
        a,
        b
      ) {
        return a.examineeSeatNum - b.examineeSeatNum;
      });
      _labelPrintList.forEach((item) => {
        var html = "";
        LODOP.NewPage();
        LODOP.ADD_PRINT_RECT(1, 8, 178, 100, 0, 1);
        LODOP.SET_PRINT_STYLEA(0, "CornerRate", 0.1);
        LODOP.SET_PRINT_PAGESIZE(1, 525, 315);
        if(this.drawModel === 1) {
          // 调整考号位置
          ariaArr[3] = 
            (item.studentName.length > 8 && examPlaceName.length > 8) 
            ? 82 
            : (item.studentName.length > 8 || examPlaceName.length > 8) 
            ? 67
            : 50;
          // 如果是分开抽签，标签上添加考场数据
          LODOP.ADD_PRINT_TEXT(ariaArr[0], 10, 100, 25, "考场:");
          LODOP.SET_PRINT_STYLEA(0, "FontSize", 11);
          LODOP.ADD_PRINT_TEXT(ariaArr[1], 10, 100, 25, "座位:");
          LODOP.SET_PRINT_STYLEA(0, "FontSize", 11);
          LODOP.ADD_PRINT_TEXT(ariaArr[2], 10, 100, 25, "姓名:");
          LODOP.SET_PRINT_STYLEA(0, "FontSize", 11);
          LODOP.ADD_PRINT_TEXT(ariaArr[3], 10, 100, 25, "考号:");
          LODOP.SET_PRINT_STYLEA(0, "FontSize", 11);
        } else {
          LODOP.ADD_PRINT_TEXT(ariaArr[0], 10, 100, 25, "座位:");
          LODOP.SET_PRINT_STYLEA(0, "FontSize", 11);
          LODOP.ADD_PRINT_TEXT(ariaArr[1], 10, 100, 25, "姓名:");
          LODOP.SET_PRINT_STYLEA(0, "FontSize", 11);
          LODOP.ADD_PRINT_TEXT(ariaArr[2], 10, 100, 25, "考号:");
          LODOP.SET_PRINT_STYLEA(0, "FontSize", 11);
        }
        let customCodeWidth = this.drawModel === 1 ? -17 : 24;
        if (item.studentName.length > 8) {
          customCodeWidth = this.drawModel === 1 ? -17 : 9;
        }
        // 如果是分开抽签，标签上添加考场数据
        html += this.drawModel === 1 ? 
          `
          <div class='omit_box' style='margin-left:15px;position: relative; top: -11px;font-size:14px;left:5px;'>${
            examPlaceName.substring(0, 8)
          }<br>${examPlaceName.substring(8)}</div>
          <div class='omit_box' style='margin-left:15px;position: relative; top: -12px;left:5px;'>${
            item.seatCode
          }</div>
          <div class='omit_box' style='margin-left:15px;position: relative; top: -15px;left:5px;font-size:14px;'>${item.studentName.substring(
            0,
            8
          )}<br>${item.studentName.substring(8)}</div>
          <div class='omit_box' style='margin-left:15px;position: relative; top: ${customCodeWidth}px;left:5px;'>${
            item.examineeCode.substring(0,14)
          }<br>${item.examineeCode.substring(14)}</div>
          ` : 
          `
          <div class='omit_box' style='margin-left:15px;position: relative; top: -1px;'>${
            item.seatCode
          }</div>
          <div class='omit_box' style='margin-left:15px;position: relative; top: 12px;left:0px;font-size:14px;'>${item.studentName.substring(
            0,
            8
          )}<br>${item.studentName.substring(8)}</div>
          <div class='omit_box' style='margin-left:15px;position: relative; top: ${customCodeWidth}px;'>${
            item.examineeCode.substring(0,14)
          }<br>${item.examineeCode.substring(14)}</div>
          `;
        LODOP.ADD_PRINT_HTM(15, 30, 220, 100, html);
      });
      LODOP.PREVIEW();
    },
    /* 时间格式化 */
    formatDateTime(date, fmt) {
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(
          RegExp.$1,
          (date.getFullYear() + "").substr(4 - RegExp.$1.length)
        );
      }
      let o = {
        "M+": date.getMonth() + 1,
        "d+": date.getDate(),
        "h+": date.getHours(),
        "m+": date.getMinutes(),
        "s+": date.getSeconds(),
      };
      for (let k in o) {
        if (new RegExp(`(${k})`).test(fmt)) {
          let str = o[k] + "";
          fmt = fmt.replace(
            RegExp.$1,
            RegExp.$1.length === 1 ? str : this.padLeftZero(str)
          );
        }
      }
      return fmt;
    },
    padLeftZero(str) {
      return ("00" + str).substr(str.length);
    },
    // 退出登录
    quitBtn() {
      quit().then((res) => {
        if (res.success) {
          removeStore("LOCAL_OSS");
          removeStore("LOCAL_MEDIA");
          removeToken();
          removeName();
          setTimeout(() => {
            // if (!getLoginRoad()) {
            // removeLoginRoad()
            // this.$router.replace('/selectLogin')
            this.$router.replace("/checkInLogin");
            // } else {
            // removeLoginRoad()
            // this.$router.replace('/cityPlatformLogin')
            // }
          }, 500);
        }
      });
    },
    // 切换上下批次
    handlePageChange(val) {
      let examSessionVal = Number(
        this.queryParam.examSession.replace("/", ":").split(":")[0]
      );
      if(val === -1 && examSessionVal === 1) return
      if(val === 1 && examSessionVal === this.examSessionList.length) return
      examSessionVal += val
      this.queryParam.examSession = `${examSessionVal}/${this.examSessionList.length}`;
      this.chooseExamSession()
    },
    labelValue(item, i) {
      if(this.drawStatusList[i]) {
        return item + '/' + this.examSessionLen + ' 已抽签'
      } 
      return item + '/' + this.examSessionLen + (this.examSessionInfoList[i].drawing ? ' 已抽签':' 未抽签')
    },
    getCurrentExamPlaceName() {
      // let str = ""
      // this.examPlaceList.forEach(it => {
      //   if(it.examPlaceId == this.queryParam.examPlace) {
      //     str = it.sysOrgExamPlaceRoomName
      //   }
      // })
      // return str
      return this.examPlaceList.filter(it => it.examPlaceId == this.queryParam.examPlace)[0].sysOrgExamPlaceRoomName
    },
  },
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {
    //清除定时器
    clearInterval(this.timeInterval);
    clearInterval(this.timer);
  },
  destroyed() {},
  activated() {},
};
</script>
<style lang="scss" scoped>
#content {
  box-sizing: border-box;
}

#herder_body {
  padding: 15px 10px;
  background-color: #1890ff;
}

#header_title {
  text-align: center;
  font-size: 18px;
  font-weight: bold;
}

#header_title,
#header_timeing {
  color: white;
}

#main_box {
  background-color: rgb(233 244 255);
  height: 100vh;
  padding-top: 10px;
}

#background_box {
  background: url("./img/drawBackGround.jpg") no-repeat;
  width: 360px;
  height: 240px;
  background-size: 100% 100%;
  -webkit-background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  -o-background-size: 100% 100%;
  position: absolute;
  bottom: 20px;
}

#exam_box {
  margin: 0 auto;
  width: 1126px;
  text-align: center;
}

#main {
  margin: 0 auto;
}

#main_header {
  margin-left: 19px;
}

#main_body {
  margin-top: 10px;
  text-align: center;
}

#main_body_time,
#main_body_distance {
  line-height: 40px;
  font-size: 17px;
}

#seat_box {
  margin-top: 10px;
}

#seat_body > ::v-deep > .el-card > .el-card__body {
  padding: 15px;
}

#main_header > ::v-deep .el-select,
.main_body_batch > ::v-deep .el-select {
  width: 130px;
}

#main_body_batch > ::v-deep .el-select .el-input__inner {
  text-align: left;
}

.seat_badge {
  height: 110px;
  margin-bottom: 10px;
}

.seat_badge > #seat_container {
  width: 100%;
  height: 100%;
  /*  display: flex;
  justify-content: center;
  align-items: center; */
  background-color: #fff;
  /* 必加项，否则样式不生效 */
  -webkit-print-color-adjust: exact;
}

#seat_container {
  height: 110px;
  border-radius: 4px;
  border: 1px solid #ebeef5;
  background-color: #fff;
  overflow: hidden;
  color: #303133;
  transition: 0.3s;
  box-shadow: 0 6px 12px 0 rgb(0 0 0 / 10%);
  position: relative;
}

#seat_container > div {
  padding: 6px;
}

#seat_container > div > img {
  float: right;
}

#seat_info {
  clear: right;
}

#seat_num {
  font-size: 32px;
  color: #1890ff;
  font-weight: bold;
  height: 98px;
  line-height: 98px;
  margin-left: 6px;
}

#examinee_info {
  color: #1890ff;
  font-size: 15px;
  font-weight: bold;
  margin-left: 16px;
  margin-top: 10px;
}

#examinee_info > div > div:nth-child(2) {
  margin-top: 9px;
  width: 100%;
  font-weight: normal;
}

.seat_badge ::v-deep .el-card__body {
  padding: 15px;
}

/* 去除页眉页脚 */
@page {
  size: auto A4 landscape;
  margin: 3mm;
}

//A4打印相关样式
#A4_seat_body {
  border-color: #ccc;
}

::v-deep #A4_seat_body .el-card__body,
#label_print_content .el-card__body {
  padding: 15px;
}

#A4_print_exam {
  text-align: center;
  font-size: 26px;
}

#A4_print_place,
#A4_print_time,
#A4_print_session {
  font-size: 20px;
}

.omit_box {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  word-break: break-all;
}
.returnBtn {
  background: #ffffff50;
  color: #fff;
}
::v-deep .btnBox {
  .el-button {
    text-align: left;
  }
}
</style>
