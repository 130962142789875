import { request, noTimeOutReq } from '@/utils/request.js'

//根据root配置文件信息查询考试信息
export function getDrawingOrgExamInfoApi () {
  return request({
    url: `/drawing/platform/list/orgExamInfo`,
    method: 'post',
  })
}

//根据考点id查询考点考试列表
export function getExamInfoListApi (sysOrgExamPlaceId) {
  return request({
    url: `/drawing/platform/list/exam/${sysOrgExamPlaceId}`,
    method: 'post',
  })
}

//查询考试信息及场次集合信息(进入抽签页面)
export function enterDrawApi (examId, sysOrgExamPlaceId) {
  return request({
    url: `/drawing/platform/get/examSessionInfo/${examId}/${sysOrgExamPlaceId}`,
    method: 'post',
  })
}

//抽签
export function drawingApi (examPlaceId, examSession) {
  return request({
    url: `/drawing/platform/drawing/${examPlaceId}/${examSession}`,
    method: 'post',
  })
}


//查询考试时间场次信息
export function getDrawExamInfoApi (examPlaceId, examSession) {
  return request({
    url: `/drawing/platform/get/examTimeInfo/${examPlaceId}/${examSession}`,
    method: 'post',
  })
}


